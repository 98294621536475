import React from "react"

import styled from "styled-components"
import LazyHero from "react-lazy-hero"

const Inner = styled.div`
position: relative;
flex: 2 5 auto;
@media (max-width: 786px) and (orientation: landscape) {
  margin-top: 1.5rem;
}
text-shadow: 1px 0px 5px rgba(0,0,0,0.95);
top: 0;
left: 0;
color: ${props => props.textColor || props.theme.colors.background};

`
const Outer = styled.div`
overflow: hidden;
position: relative;
width: 100vw;
left: 50%;
right: 50%;
margin-left: -50vw;
margin-right: -50vw;
margin-bottom: 2rem;
background-color: ${props => props.background || props.theme.colors.primary};
`
const Heading = styled.div`
position: relative;
color: ${props => props.textColor || props.theme.colors.background};

top: -10vh;
@media (min-height: 768px) {
  top: -15vh;
}
@media(max-width: 320px) {
  h1 {
    font-size: 2.3rem;
  }
  h2 {
    font-size: 1.594176rem;
  }
}
`

const Hero = (props) => {
  return (
    <Outer>
      <LazyHero imageSrc={props.imageSrc}
        opacity={props.opacity || 0.0}
        color={props.color || '#666'}
        isFixed={false}
        parallaxOffset={0}
        minHeight={props.minHeight || "33vh"}
        >
          <Heading>{props.heading}</Heading>
        <Inner textColor={props.textColor}>{props.children}</Inner>
      </LazyHero>
    </Outer>


  )
}

export default Hero